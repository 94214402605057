'use client';

import * as React from 'react';
import {
    RainbowKitProvider,
    connectorsForWallets,
    darkTheme,
    lightTheme,
    DisclaimerComponent
} from '@rainbow-me/rainbowkit';
import {
    okxWallet,
    rabbyWallet,
    trustWallet,
    braveWallet,
    bifrostWallet,
    metaMaskWallet,
    coinbaseWallet,
    walletConnectWallet,
    injectedWallet
} from '@rainbow-me/rainbowkit/wallets';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, http } from 'wagmi'
import {ToastContainer} from "react-toastify";
import { SnackbarProvider } from 'notistack';
import { Chain } from '@rainbow-me/rainbowkit'
import { createConfig } from 'wagmi';

const projectId = "d26c9686de353443a23c95b5d207fbc3"
//const alchemyId = "pF8DA7XBkRJxiUdnMtNlbxESBDw1El5w"

export const flare = {
    id: 14,
    name: 'Flare',
    nativeCurrency: {
        decimals: 18,
        name: 'flare',
        symbol: 'FLR',
    },
    rpcUrls: {
        public: { http: ['https://flarerpc.dax.best/ext/C/rpc'] },
        default: { http: ['https://flarerpc.dax.best/ext/C/rpc'] },
    },
    blockExplorers: {
        default: { name: 'Flare Explorer', url: 'https://flare-explorer.flare.network' },
    },
} as const satisfies Chain


export const flareTestnet = {
    id: 114,
    name: 'C2Flare',
    nativeCurrency: {
        decimals: 18,
        name: 'c2flare',
        symbol: 'C2FLR',
    },
    rpcUrls: {
        public: { http: ['https://c2rpc.ftso.best/ext/C/rpc'] },
        default: { http: ['https://c2rpc.ftso.best/ext/C/rpc'] },
    },
    blockExplorers: {
        default: { name: 'Flare Explorer', url: 'https://coston2-explorer.flare.network' },
    },
} as const satisfies Chain

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [
            injectedWallet,
            metaMaskWallet,
            bifrostWallet,
            coinbaseWallet,
            walletConnectWallet,
        ],
    },
    {
        groupName: 'Others',
        wallets: [
            trustWallet,
            braveWallet,
            okxWallet,
            rabbyWallet,
        ],
    },
],
    {
        appName: 'Dax.Best',
        projectId: projectId,
    });

const config = createConfig({
    connectors,
    chains: [flare],
    transports: {
        [flare.id]: http(),
        [flareTestnet.id]: http(),
    },
});

const queryClient = new QueryClient()

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
    <Text>
        By connecting your wallet to this app, you agree to the app s
        <Link href="https://dax.best/terms/">Terms of Service</Link> and
        <Link href="https://dax.best/privacy/">Privacy Policy</Link>
    </Text>
);

const AppInfo = {
    appName: 'Dax.Best',
    disclaimer: Disclaimer
};


export function Providers({ children }: { children: React.ReactNode }) {
    const [mounted, setMounted] = React.useState(false);
    const [isDarkMode, setIsDarkMode] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
        const theme = localStorage.getItem('theme');
        setIsDarkMode(theme === 'dark');
    }, []);
    return (
        <SnackbarProvider maxSnack={5}>
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <RainbowKitProvider appInfo={AppInfo}
                                        theme={{
                                            lightMode: lightTheme({
                                                accentColor: '#f67a3c',
                                                accentColorForeground: 'white',
                                                borderRadius: 'small',
                                                fontStack: 'system',
                                                overlayBlur: 'small',
                                            }),
                                            darkMode: darkTheme({
                                                accentColor: '#f67a3c',
                                                accentColorForeground: 'white',
                                                borderRadius: 'small',
                                                fontStack: 'system',
                                                overlayBlur: 'small',
                                            }),
                                        }}>
                        {mounted && children}
                    </RainbowKitProvider>
                </QueryClientProvider>
                <ToastContainer />
            </WagmiProvider>
        </SnackbarProvider>
    );
}
