import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/ftsolottery/dax.best/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/ftsolottery/dax.best/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/ftsolottery/dax.best/src/app/providers.tsx");
